<template>
    <div class="container">
       <back/>  
       <div class="content">
        <div class="box-right">
				<div class="panel-box" v-for="item in panelList" :key="item.type">
					<div class="panel-header" :style="{ backgroundColor: item.color }">
						<span><i class="el-icon-s-flag"></i>{{ item.text }}</span>
						<i class="el-icon-circle-plus-outline" style="font-size: 30px" @click="openDialog(item)"></i>
					</div>
					<div class="panel-container">
						<div v-if="item.workList.length === 0 "><el-empty description="暂无数据"></el-empty></div>
						<div v-else style="width: 100%;height: 100%;overflow: auto;">
							<div class="panel-content" :class="{ bg: i.completion }" v-for="(i, j) in item.workList"
								:key="j">
								<el-checkbox :value="i.completion != 0" @change="changeBox($event, i)"></el-checkbox>
								<div class="title" @click="handleUpdate(i)" :class="{ active: i.completion }">
									{{ i.taskContent }}
								</div>
								<i class="el-icon-delete" @click="handleDelete(i.taskId)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
       </div>
    </div>
</template>

<script>
import back from "@/components/back";
    export default {
        components: {
            back,
        },
        data() {
            return {
                panelList: [{
						text: "重要且紧急",
						color: "#C83434",
						type: 1,
						workList: [],
					},
					{
						text: "重要不紧急",
						color: "#FC9E30",
						type: 2,
						workList: [],
					},
					{
						text: "紧急不重要",
						color: "#34A4C8",
						type: 3,
						workList: [],
					},
					{
						text: "不重要不紧急",
						color: "#8ABF33",
						type: 4,
						workList: [],
					},
				],
            }
        },
    }
</script>

<style lang="scss" scoped>
.container {
    width: 100vw;
    height: 100vh;
    padding: 30px 50px;
    background-image: linear-gradient(#FFFDFB, #ffe5d1);
.box-right {
    margin-top: 100px;
				// width: 65%;

				display: flex;

				justify-content: space-between;
				flex-wrap: wrap;

				.panel-box {
					border-radius: 5px;
					box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
					// border: 1px solid #000;
					width: 49%;
					height: 450px;
					margin-bottom: 20px;
					overflow: auto;

					.panel-header {
						padding: 0 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: sticky;
						top: 0;
						font-size: 22px;
						font-weight: bold;
						// background-color: #C83434;
						color: #fff;
						line-height: 50px;
						text-align: center;
						z-index: 999;

						span {
							display: flex;
							justify-content: center;
							align-items: center;

							i {
								font-size: 27px;
								color: rgba(0, 0, 0, .5);
							}
						}
					}

					.panel-container {
						height: calc(100% - 50px);
						overflow: hidden;
						background-color: #f5f6f7;

						.panel-content {
							background-color: rgb(227, 227, 227, .36);
							border-bottom: 1px solid #ccc;
							padding: 10px 20px;

							font-size: 20px;
							display: flex;
							justify-content: space-between;
							align-items: center;

							.title {
								// max-width: 350px;
								margin-right: auto;
								margin-left: 20px;
								cursor: pointer;
								// word-break: break-all;
								// text-overflow: ellipsis;
								// display: -webkit-box;
								// -webkit-box-orient: vertical;
								// -webkit-line-clamp: 2;
								// /* 这里是超出几行省略 */
								// overflow: hidden;
							}

							.active {
								text-decoration: line-through;
								color: #ccc;
							}
						}
					}

					.bg {
						background-color: aliceblue;
					}
				}
			}
}
</style>